<template>
  <a-button type="primary" class="m-b-15"
    v-has:addAnnouncement="()=>{$router.push('/brandMsg/announcement/announcement_add?type=add')}"><PlusOutlined/>发布公告</a-button>
  <a-form layout='inline' :model="search">
    <a-form-item label="关键词">
      <a-input v-model:value="search.keywords" placeholder="请输入关键字进行搜索" style="width:300px"></a-input>
    </a-form-item>
    <a-form-item label="通知对象">
      <a-select v-model:value="search.send_type" style="width:120px">
        <a-select-option value="">全部</a-select-option>
        <a-select-option :value="1">全部品牌</a-select-option>
        <a-select-option :value="2">指定品牌</a-select-option>
        <a-select-option :value="3">部分不可见</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="发布时间">
      <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" @click="onSearch()">搜索</a-button>
    </a-form-item>
  </a-form>
  <a-table :pagination='pagination' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='list'
    class="m-t-20" @change="changePage">
    <template #action="{record}">
      <div class="s_flex_ail">
        <a-button size="small"
          v-has:lookAnnouncement="()=>{$router.push('/brandMsg/announcement/announcement_look?type=look&id='+record.id)}"
          v-if='record.status'>详情
        </a-button>
        <a-button size="small" type="primary"
          v-has:editAnnouncement="()=>{$router.push('/brandMsg/announcement/announcement_edit?type=edit&id='+record.id)}"
          v-else>编辑
        </a-button>
        <a-button size="small" type="primary" class="m-l-15" danger v-has:delAnnouncement="()=>delAnnouncement(record)">
          删除</a-button>
      </div>
    </template>
  </a-table>
</template>
<script>
  import { noticeList, noticeDel } from '@/api/brand.js'
  import { reactive, toRefs, ref, inject, onMounted } from 'vue';
  import { $iscode } from '@/utils/app';
  import { message } from 'ant-design-vue';
  import { PlusOutlined } from "@ant-design/icons-vue";
  const columns = [
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "通知对象",
      dataIndex: "send_type_text"
    },
    {
      title: "发布人",
      dataIndex: "publisher",
    },
    {
      title: "发布状态",
      dataIndex: "status",
      customRender: function ({ record }) {
        return record.status ? '已发布' : '待发布'
      }
    },
    {
      title: "发布时间",
      dataIndex: "create_time",
      customRender: function ({ record }) {
        return record.create_time ? record.create_time : '-'
      }
    },
    {
      title: "操作",
      dataIndex: "action",
      slots: { customRender: 'action' },
      width: 150
    },
  ]
  export default {
    components: {
      PlusOutlined
    },
    setup() {
      const $Modal = inject("$Modal");
      const search = ref({
        keywords: '',
        send_type: '',
        start_time: '',
        end_time: '',
        page: 1,
        limit: 10,
        status:'',
      })
      const state = reactive({
        time: [],
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15', '20', '25'],
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
        },
        listLoading: false,
        list: []
      })
      const methods = {
        onChange(e) {
          state.time = e;
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        },
        changePage(e) {
          search.value.limit = e.pageSize;
          search.value.page = e.current;
          state.pagination.current = e.current;
          state.pagination.pageSize = e.pageSize;
          methods.getNoticeList();
        },
        delAnnouncement(record) {
          $Modal.confirm({
            title: '提示',
            content: '确定要删除该公告？',
            centered:true,
            onOk: async function () {
              const hide = message.loading('加载中...')
              let res = await noticeDel({ id: record.id }).then(res => res.data).catch((error) => error);
              setTimeout(hide)
              if ($iscode(res)) {
                methods.getNoticeList();
              }
            }
          })
        },
        async getNoticeList() {
          state.listLoading = true;
          state.list = [];
          let res = await noticeList({ ...search.value }).then(res => res.data).catch((error) => error);
          state.listLoading = false;
          if ($iscode(res)) {
            state.list = res.data.data || [];
            state.pagination.current = res.data.current_page;
            search.value.page = res.data.current_page || 1;
            state.pagination.total = res.data.total || 0;
          } else {
            message.error(res.msg)
          }
        },
        onSearch() {
          search.value.page = 1;
          methods.getNoticeList();

        }
      }

      onMounted(() => {
        methods.getNoticeList()
      })
      return {
        ...toRefs(state),
        search,
        ...methods,
        columns
      }
    },
  }
</script>